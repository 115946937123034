import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, googleProvider } from './firebaseConfig'; // Import Firebase config (only Google provider now)
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateUserPearls } from '../app/reducers/userSlice';

// Create the Auth Context
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Listen for auth state changes (Firebase)
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser); // Update user state when auth state changes
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Sign In with Email and Password
  const signInWithEmail = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user); // Update user state on sign in
      toast.success('Signed in successfully');
    } catch (error) {
      toast.error('Failed to sign in: ' + error.message);
    }
  };

  // Sign Up with Email and Password
  const signUpWithEmail = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      setUser(user); // Update user state on sign up

      // Dispatch action to update user pearls in the Redux store
      dispatch(updateUserPearls({ pearls: 200 }));

      // Display success message
      toast.success('User registered successfully. You have received 200 pearls as a sign-up reward!');
    } catch (error) {
      toast.error('Failed to sign up: ' + error.message);
    }
  };

  // Sign in with Google
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      setUser(result.user); // Update user state on Google sign in
      toast.success('Signed in with Google');
    } catch (error) {
      toast.error('Failed to sign in with Google: ' + error.message);
    }
  };

  // Sign out
  const logOut = async () => {
    try {
      await signOut(auth);
      setUser(null); // Set user to null after logout
      toast.success('Successfully logged out');
    } catch (error) {
      toast.error('Failed to log out: ' + error.message);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        signInWithEmail,
        signUpWithEmail,
        signInWithGoogle,
        logOut,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};
