import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { productData } from "../data/productData";
import "../assets/css/productView.css";
import {
  FaStar,
  FaBolt,
  FaShoppingCart,
  FaMinus,
  FaPlus,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { addToCart } from "../app/actions/actionsCart";

const ProductView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productId } = useParams();
  const product = productData.products.find((p) => p.id === productId);

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedMeter, setSelectedMeter] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [quantity, setQuantity] = useState(1);

  if (!product) {
    return <div className="error-message">Product not found!</div>;
  }

  const handleAddToCart = () => {
    if (product.colors && !selectedColor) {
      toast.error("Please select a color before adding to cart.");
      return;
    }
    const cartItem = {
      ...product,
      selectedSize,
      selectedMeter,
      selectedColor,
      qty: quantity,
    };
    dispatch(addToCart(cartItem));
    toast.success(`${product.productName} has been added to your cart!`);
  };

  const handleBuyNow = () => {
    if (
      (product.sizeOptions && !selectedSize) ||
      (product.colors && !selectedColor) ||
      (product.meters && !selectedMeter)
    ) {
      toast.error("Please select all options before proceeding.");
      return;
    }

    navigate("/checkout", {
      state: {
        cart: [
          {
            ...product,
            selectedSize,
            selectedMeter,
            selectedColor,
            qty: quantity,
          },
        ],
      },
    });
  };

  const handleQuantityChange = (type) => {
    if (type === "increment") {
      setQuantity(quantity + 1);
    } else if (type === "decrement" && quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <div className="product-view-container">
      {/* Product Images */}
      <div className="product-images-section">
        <img
          src={product.media[selectedImageIndex]}
          alt={product.productName}
          className="main-product-image"
        />
        <div className="thumbnail-container">
          {product.media.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={`thumbnail ${
                index === selectedImageIndex ? "thumbnail-active" : ""
              }`}
              onClick={() => setSelectedImageIndex(index)}
            />
          ))}
        </div>
      </div>

      {/* Product Details */}
      <div className="product-details-section">
        <div className="product-details-contant-info">
          <h1 className="product-title">{product.productName}</h1>
          <p className="product-description">{product.description}</p>
          <div className="product-reviews-section">
            <p>Reviews:</p>
            <div className="stars-container">
              {Array.from({ length: 5 }).map((_, index) => (
                <FaStar
                  key={index}
                  className={`star ${
                    index < Math.round(product.ratings) ? "star-filled" : ""
                  }`}
                />
              ))}
              <span>({product.ratings.toFixed(1)})</span>
            </div>
          </div>
          <div className="quantity-section">
            <button
              onClick={() => handleQuantityChange("decrement")}
              className="quantity-btn"
              aria-label="Decrease quantity"
            >
              <FaMinus />
            </button>
            <span className="quantity-value">{quantity}</span>
            <button
              onClick={() => handleQuantityChange("increment")}
              className="quantity-btn"
              aria-label="Increase quantity"
            >
              <FaPlus />
            </button>
          </div>
          <div className="product-price-section">
            {product.oldPrice && (
              <span className="old-price">
                ₨ <del>{product.oldPrice}</del>
              </span>
            )}
            <span className="current-price">₨ {product.price * quantity}</span>
          </div>
          {product.colors && product.colors.length > 0 && (
            <div className="color-options-section">
              <p>Colors:</p>
              <div className="color-options">
                {product.colors.map((color, index) => (
                  <div
                    key={index}
                    className={`color-box ${
                      selectedColor === color ? "color-active" : ""
                    }`}
                    style={{ backgroundColor: color.toLowerCase() }}
                    onClick={() => setSelectedColor(color)}
                  ></div>
                ))}
              </div>
            </div>
          )}
          {product.sizeOptions && (
            <div className="size-options-section">
              <p>Sizes:</p>
              <select
                value={selectedSize}
                onChange={(e) => setSelectedSize(e.target.value)}
              >
                <option value="">Select Size</option>
                {product.sizeOptions.map((size, index) => (
                  <option key={index} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          )}
          {product.meters && (
            <div className="meter-options-section">
              <p>Meters:</p>
              <select
                value={selectedMeter}
                onChange={(e) => setSelectedMeter(e.target.value)}
              >
                <option value="">Select Meterage</option>
                <option value={product.meters}>{product.meters}m</option>
              </select>
            </div>
          )}
        </div>
        <div className="action-buttons-section">
          <button onClick={handleAddToCart} className="add-cart-btn">
            Add to Cart <FaShoppingCart />
          </button>
          <button onClick={handleBuyNow} className="buy-now-btn">
            Buy Now <FaBolt />
          </button>
        </div>
      </div>
      <ToastContainer />
      <div className="related-products-section">
        <h3>Related Products</h3>
        <div className="related-products-grid">
          {productData.products
            .filter(
              (related) =>
                related.firstLevelCategory === product.firstLevelCategory &&
                related.id !== product.id
            )
            .slice(0, 4)
            .map((related) => (
              <div
                key={related.id}
                className="related-product-card"
                onClick={() => navigate(`/product-view-details/${related.id}`)}
              >
                <img
                  src={related.media[0]}
                  alt={related.productName}
                  className="related-product-img"
                />
                <div className="related-product-info">
                  <p className="related-product-name">{related.productName}</p>
                  <div className="related-product-price">
                    {related.oldPrice && (
                      <p className="related-product-old-price">
                        <del>₨ {related.oldPrice}</del>
                      </p>
                    )}
                    <p className="related-product-current-price">
                      ₨ {related.price}
                    </p>
                  </div>
                  <div className="related-product-rating">
                    {[...Array(5)].map((_, i) => (
                      <FaStar
                        key={i}
                        color={
                          i < Math.floor(product.ratings)
                            ? "#ffc107"
                            : "#e4e5e9"
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProductView;
