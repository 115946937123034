import React from "react";
import { Routes, Route } from "react-router-dom";

// Clothing Pages
import WomenPage from "../pages/Categories/WomenPage";
import MenPage from "../pages/Categories/MenPage";
import KidsPage from "../pages/Categories/KidsPage";

// Food Pages
import Food from "../pages/Categories/FoodPage";
// Other Pages
import HealthCare from "../pages/Categories/HealthCare";
import TechHub from "../pages/Categories/TechHub";

// Informational Pages
import About from "../pages/MhbInfo/About";
import Contact from "../pages/MhbInfo/Contact";
import Delivery from "../pages/MhbInfo/Delivery";
import Certifications from "../pages/MhbInfo/Certifications";
import ReturnPolicy from "../pages/MhbInfo/ReturnPolicy";
import SecurePayment from "../pages/MhbInfo/SecurePayment";
import TermsCondition from "../pages/MhbInfo/TermsConditions";
import NotFound from "../pages/NotFound";

const AllPages = () => {
    return (
        <Routes>
            {/* Clothing Routes */}
            <Route path="/women" element={<WomenPage />} />
            <Route path="/men" element={<MenPage />} />
            <Route path="/kids" element={<KidsPage />} />

            {/* Food Routes */}
            <Route path="/food" element={<Food />} />
            {/* Other Categories */}
            <Route path="/health-care" element={<HealthCare />} />
            <Route path="/tech-hub" element={<TechHub />} />

            {/* Informational Routes */}
            <Route path="/about-us" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/delivery" element={<Delivery />} />
            <Route path="/certifications" element={<Certifications />} />
            <Route path="/return-policy" element={<ReturnPolicy />} />
            <Route path="/secure-payment" element={<SecurePayment />} />
            <Route path="/terms-conditions" element={<TermsCondition />} />

            {/* Fallback Route */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AllPages;
