import React, { useState } from "react";
import { auth, googleProvider } from "../../context/firebaseConfig";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { FcGoogle } from "react-icons/fc";
import { FaEye, FaEyeSlash, FaUser, FaEnvelope, FaLock } from "react-icons/fa";
import "../../assets/css/signInSignUp.css";
import mhbLogo from "../../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { updateUserPearls } from "../../app/reducers/userSlice";
import { useNavigate } from "react-router-dom";

const SignInSignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSignUp, setIsSignUp] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    if (password.length < 6) {
      setError("Password should be at least 6 characters long.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      dispatch(updateUserPearls({ pearls: 200 }));

      alert(`User registered successfully! Welcome ${user.displayName || firstName}. You have received 200 pearls as a sign-up reward!`);

      navigate("/"); // Redirect to home page
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignIn = async (event) => {
    event.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      alert(`Signed in successfully!`);

      navigate("/"); // Redirect to home page
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      alert("Signed in with Google! You have received 200 pearls as a sign-up reward!");
      dispatch(updateUserPearls({ pearls: 200 }));

      navigate("/"); // Redirect to home page
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="auth-container">
      <img src={mhbLogo} alt="MHBStore Logo" className="auth-logo" />
      <h2 className="auth-heading">{isSignUp ? "Sign Up" : "Sign In"}</h2>

      {error && <div className="auth-error">{error}</div>}

      {isSignUp ? (
        <form onSubmit={handleSignUp} className="form-signup">
          <div className="auth-form-group">
            <FaUser className="auth-icon" />
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder=" "
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="firstName" className="auth-label">
              First Name
            </label>
          </div>

          <div className="auth-form-group">
            <FaEnvelope className="auth-icon" />
            <input
              type="email"
              name="email"
              id="email"
              placeholder=" "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="email" className="auth-label">
              Email
            </label>
          </div>

          <div className="auth-form-group password-field">
            <FaLock className="auth-icon" />
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder=" "
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="password" className="auth-label">
              Password
            </label>
            <span onClick={() => setShowPassword(!showPassword)} className="auth-password-toggle">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>

          <div className="auth-form-group password-field">
            <FaLock className="auth-icon" />
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              id="confirmPassword"
              placeholder=" "
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="confirmPassword" className="auth-label">
              Confirm Password
            </label>
            <span
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="auth-password-toggle"
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>

          <button type="submit" className="auth-btn-signup">Sign Up</button>
        </form>
      ) : (
        <form onSubmit={handleSignIn} className="form-signin">
          <div className="auth-form-group">
            <FaEnvelope className="auth-icon" />
            <input
              type="email"
              name="email"
              id="email"
              placeholder=" "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="email" className="auth-label">
              Email
            </label>
          </div>

          <div className="auth-form-group password-field">
            <FaLock className="auth-icon" />
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder=" "
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="password" className="auth-label">
              Password
            </label>
            <span onClick={() => setShowPassword(!showPassword)} className="auth-password-toggle">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>

          <button type="submit" className="auth-btn-signin">Sign In</button>
        </form>
      )}

      <div className="auth-social-buttons">
        <button onClick={handleGoogleSignIn} className="auth-btn-google">
          <FcGoogle className="google-icon" /> Sign in with Google
        </button>
      </div>

      <p onClick={() => setIsSignUp(!isSignUp)} className="auth-toggle">
        {isSignUp ? "Already have an account? Sign In" : "New here? Sign Up to earn 200 pearls!"}
      </p>
    </div>
  );
};

export default SignInSignUp;
