import { configureStore } from '@reduxjs/toolkit';
import cartReducer from '../app/reducers/cartSlice';
import favoritesReducer from '../app/reducers/favoritesSlice'; // Import the favorites reducer
import menDataSlice from './reducers/menDataSlice';
import searchResultReducer from './reducers/searchResultSlice';
import userSlice from '../app/reducers/userSlice';
const store = configureStore({
  reducer: {
    cart: cartReducer, 
    favorites: favoritesReducer,  
    product: menDataSlice,
    searchResults: searchResultReducer,
    user: userSlice
  },
});

export default store;
