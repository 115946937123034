import discountImage from "../assets/images/slider-images/1731427519864_New_Project_11.webp";

const discountPopupData = {
  title: "Special 5-Day 20% Off Discount!",
  description: "Enjoy 20% off on all products for the next 5 days. Don't miss out!",
  startDate: "2024-11-22T09:00:00",
  endDate: "2024-11-27T23:59:59",
  image: discountImage,
  isActive: false, // New flag to control popup activation
};

export default discountPopupData;
