import React from 'react';
import { useSelector } from 'react-redux';

const SearchResultsPage = () => {
  const { results, loading, error } = useSelector((state) => state.searchResults);

  return (
    <div className="search-results">
      <h2>Search Results</h2>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {results.length > 0 ? (
        <div className="products-grid">
          {results.map((product) => (
            <div key={product.id} className="product-card">
              <img src={product.image} alt={product.name} />
              <h3>{product.name}</h3>
              <p>{product.price}</p>
            </div>
          ))}
        </div>
      ) : (
        !loading && <p>No products found.</p>
      )}
    </div>
  );
};

export default SearchResultsPage;
