import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menu: [], // Initialize as an empty array
};

const menDataSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    addProduct: (state, action) => {
      const { menuId, subCategoryName, product } = action.payload;
      const menu = state.menu.find((m) => m.id === menuId);
      if (menu) {
        const subCategory = menu.subcategories.find(
          (sub) => sub.name === subCategoryName
        );
        if (subCategory) {
          subCategory.products = [...(subCategory.products || []), product];
        }
      }
    },
  },
});

export const { setMenu, addProduct } = menDataSlice.actions;
export default menDataSlice.reducer;
