import React from "react";
import { FaShoppingCart } from "react-icons/fa"; // React Icon for a shopping cart
import "../assets/css/preloader.css";

const Preloader = () => {
  return (
    <div className="preloader-wrapper-pro">
      <div className="preloader-content-pro">
        <FaShoppingCart className="preloader-icon-pro" />
        <div className="preloader-message-text-pro">Good things take time!</div>
        <div className="preloader-progress-bar-pro">
          <div className="preloader-progress-fill-pro"></div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
