import {
  clothingImage,
  foodImage,
  cosmeticsImage,
  fashionImage,
  healthcareImage,
  techImage,
  toysImage,
} from "../assets/images/images";
const sliderData = [
  {
    id: 1,
    image: clothingImage,
    altText: "Clothing Promotion",
  },
  {
    id: 2,
    image: foodImage,
    altText: "Food Promotion",
  },
  {
    id: 3,
    image: cosmeticsImage,
    altText: "Cosmetics Promotion",
  },
  {
    id: 4,
    image: fashionImage,
    altText: "Fashion Promotion",
  },
  {
    id: 5,
    image: healthcareImage,
    altText: "Health Care Promotion",
  },
  {
    id: 6,
    image: techImage,
    altText: "Tech Promotion",
  },
  {
    id: 7,
    image: toysImage,
    altText: "Toys Promotion",
  },
];

export default sliderData;
