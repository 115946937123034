import React from 'react'
import "../assets/css/test.css";
const TestPage = () => {
  return (
    <div>
      <h1>Test</h1>
    </div>
  )
}

export default TestPage
