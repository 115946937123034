import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { removeFromFavorites } from "../app/reducers/favoritesSlice";
import { addToCart } from "../app/actions/actionsCart";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaStar } from "react-icons/fa";
import { LiaCartPlusSolid } from "react-icons/lia";
import "../assets/css/favoritesPage.css";
import { IoHeartDislikeCircleOutline } from "react-icons/io5";
import { productData } from "../data/productData"; // Import all products

const FavoriteList = () => {
  const dispatch = useDispatch();
  const favoriteIds = useSelector((state) => state.favorites); // Array of favorite product IDs
  const favoriteProducts = productData.products.filter((product) =>
    favoriteIds.includes(product.id)
  ); // Map favorite IDs to product data

  const handleRemoveFromFavorites = (product) => {
    dispatch(removeFromFavorites(product.id));
    toast.info(`${product.productName} removed from favorites.`);
  };

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    toast.success(`${product.productName} added to cart!`);
  };

  return (
    <div className="favorites-container-main">
      {/* Page Banner */}
      <div className="favorites-banner">
        <div className="banner-content">
          <h1>Favorite Products</h1>
          <p>
            <Link to="/">Home</Link> / Favorite Products
          </p>
        </div>
      </div>

      {/* Favorites List */}
      <div className="favorites-list">
        {favoriteProducts.length > 0 ? (
          <div className="favorites-grid">
            {favoriteProducts.map((product) => (
              <div key={product.id} className="favorites-card">
                {product.discount && (
                  <span className="favorites-sale-tag">SALE {product.discount}</span>
                )}
                <img
                  src={product.media[0]}
                  alt={product.productName}
                  className="favorites-product-image"
                />
                <Link
                  to={`/product-view-details/${product.id}`}
                  className="favorites-product-details-link"
                >
                  <div className="favorites-product-info">
                    <h5 className="favorites-product-name">{product.productName}</h5>
                    <div className="favorites-product-price">
                      {product.oldPrice && (
                        <span className="favorites-old-price">
                          ₨ <del>{product.oldPrice}</del>
                        </span>
                      )}
                      <span className="favorites-new-price">₨ {product.price}</span>
                    </div>
                    <div className="favorites-rating">
                      {[...Array(5)].map((_, i) => (
                        <FaStar
                          key={i}
                          color={
                            i < Math.floor(product.ratings)
                              ? "#ffc107"
                              : "#e4e5e9"
                          }
                        />
                      ))}
                    </div>
                  </div>
                </Link>

                <div className="favorites-action-buttons">
                  <span
                    className="favorites-add-to-cart-btn"
                    onClick={() => handleAddToCart(product)}
                  >
                    <LiaCartPlusSolid />
                  </span>
                  <span
                    className="favorites-remove-btn"
                    onClick={() => handleRemoveFromFavorites(product)}
                  >
                    <IoHeartDislikeCircleOutline size={24} />
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="favorites-empty-state">
            <h2>No Favorite Products</h2>
            <p>
              Browse products and add them to your favorites to see them here.
            </p>
            <Link to="/" className="favorites-back-to-home">
              Continue Shopping
            </Link>
          </div>
        )}
      </div>

      <ToastContainer autoClose={1000} />
    </div>
  );
};

export default FavoriteList;
