import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import mhbLogo from "../assets/images/logo.png";
import { IoIosSearch } from "react-icons/io";
import { FaCartShopping } from "react-icons/fa6";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import { FaBars, FaTimes } from "react-icons/fa";
import pakistanIcon from "../assets/icons/pakistan.png";
import useScrollTrigger from "../hooks/useScrollTrigger";
import "../assets/css/navbar.css";
import defualtAvatar from "../assets/images/default-avatar.png";
import { fetchSearchResults, clearSearchResults } from "../app/reducers/searchResultSlice";
import { toast } from "react-toastify";
import PearlsIcon from "../assets/icons/peeral.png";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../context/authContext"; // Import the useAuth hook

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, logOut } = useAuth(); // Access user data and logOut function from AuthContext

  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false); // Profile menu state

  const scrollDirection = useScrollTrigger();

  const cart = useSelector((state) => state.cart?.cart || []);
  const cartCount = cart.reduce((acc, item) => acc + item.qty, 0);

  const favorites = useSelector((state) => state.favorites || []);
  const favoriteCount = favorites.length;

  const pearlsCoins = useSelector((state) => state.user?.pearls || 0);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      dispatch(fetchSearchResults(searchQuery));
      navigate(`/search?query=${searchQuery}`);
    } else {
      toast.error("Search query cannot be empty!");
    }
  };

  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchReset = () => {
    setSearchQuery("");
    dispatch(clearSearchResults());
  };

  const handleProfileMenuToggle = () => setIsProfileMenuOpen(!isProfileMenuOpen);

  const handleLogout = () => {
    logOut(); // Use the logOut function from the AuthContext
    toast.success("Successfully logged out!");
    navigate("/");
  };

  useEffect(() => {
    console.log("User updated:", user); // Debugging log
    setIsProfileMenuOpen(false); // Reset profile menu on user update
  }, [user]);

  return (
    <>
      {/* Header */}
      <header
        className={`header-container ${scrollDirection === "down" ? "hide" : "show"}`}
      >
        <div className="logo">
          <Link to="/">
            <img src={mhbLogo} alt="MHB Store Logo" />
          </Link>
        </div>

        {/* Search Bar */}
        <form className="search-bar-container" onSubmit={handleSearchSubmit}>
          <div className="icon">
            <IoIosSearch className={`swap-on ${searchQuery ? "hidden" : ""}`} />
          </div>
          <label htmlFor="search">
            <input
              type="text"
              id="search"
              placeholder="What are you looking for today?"
              value={searchQuery}
              onChange={handleSearchInput}
              required
              autoComplete="off"
            />
            {searchQuery && (
              <button
                type="button"
                className="close-btn"
                onClick={handleSearchReset}
              >
                <FaTimes />
              </button>
            )}
          </label>
        </form>

        {/* Right Icons */}
        <div className="right-icons">
          <div className="locations-container">
            <img src={pakistanIcon} alt="Pakistan Flag" className="flag-icon" />
          </div>

          <div className="pearls-container">
            <Link to="/peerals" className="pearls-link">
              <span className="pearls-count">{pearlsCoins}</span>
              <img src={PearlsIcon} alt="pearls" className="pearls-icon" />
            </Link>
          </div>

          <div className="fav-prodect-list-container">
            <Link to="/favourit-list" className="fav-link">
              {favoriteCount > 0 && (
                <span className="cart-badge-dot">{favoriteCount}</span>
              )}
              <MdOutlineFavoriteBorder className="fav-icon" />
              <span>Wish List</span>
            </Link>
          </div>

          <div className="cart-icon-wrapper">
            <Link to="/cart">
              <FaCartShopping className="icon" />
              {cartCount > 0 && (
                <span className="cart-badge-dot">{cartCount}</span>
              )}
            </Link>
          </div>

          {/* User Profile Section */}
          {user ? (
            <div className="profile-container">
              <div className="profile-info" onClick={handleProfileMenuToggle}>
                <img
                  src={user.photoURL || defualtAvatar}
                  alt={user.displayName}
                  className="profile-image"
                />
                <span>{user.displayName}</span>
              </div>
              {isProfileMenuOpen && (
                <div className="profile-menu">
                  <Link to="/my-account">My Account</Link>
                  <Link to="/peerals">My Pearls</Link>
                  <button onClick={handleLogout} className="logout-btn">Logout</button>
                </div>
              )}
            </div>
          ) : (
            <div className="profile-container">
              <Link to="/signIn-signUp" className="signIn-signUp-btn">
                Sign In
              </Link>
            </div>
          )}
        </div>
      </header>

      {/* Navbar */}
      <nav className={`navbar-container ${isOpen ? "active" : ""}`}>
        <div className="hamburger" onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={`nav-links ${isOpen ? "active" : ""}`}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/men">Men</Link></li>
          <li><Link to="/women">Women</Link></li>
          <li><Link to="/kids">Kids</Link></li>
          <li><Link to="/food">Food</Link></li>
          <li><Link to="/health-care">Health Care</Link></li>
          <li><Link to="/tech-hub">Tech Hub</Link></li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
