import m1Image from "../assets/images/products/Clothes/men/DSC00789.webp";
import m2Image from "../assets/images/products/Clothes/men/DSC00851.webp";
import c1Image from "../assets/icons/clothes-icon.png";

export const productData = {
  categories: [
    { id: '001', categoryName: 'Men', color: 'Blue', media: [c1Image], published: true },
    { id: '002', categoryName: 'Women', color: 'Pink', media: ['https://via.placeholder.com/300x150?text=Women+Category'], published: true },
    { id: '003', categoryName: 'Kids', color: 'Yellow', media: ['https://via.placeholder.com/300x150?text=Kids+Category'], published: true },
    { id: '004', categoryName: 'Food', color: 'Green', media: ['https://via.placeholder.com/300x150?text=Food+Category'], published: true },
    { id: '005', categoryName: 'Health Care', color: 'Red', media: ['https://via.placeholder.com/300x150?text=Health+Care+Category'], published: true },
    { id: '006', categoryName: 'Tech Hub', color: 'Black', media: ['https://via.placeholder.com/300x150?text=Tech+Hub+Category'], published: true },
  ],
  subCategories: [
    { parentCategory: "001", subCategoryName: "Unstitched Suits", smallCategoryName: ["Cotton", "Cotton Blends", "Linen", "Lawn", "Khaddar", "Karandi", "Wash & Wear", "Boski"] },
    { parentCategory: "001", subCategoryName: "Ready-to-Wear", smallCategoryName: ["Shalwar Kameez", "Kurta Shalwar", "Pant and Shirts", "Jeans & T-Shirts"] },
    { parentCategory: "001", subCategoryName: "Footwear", smallCategoryName: ["Sneakers & Casual Shoes", "Boots", "Formal Shoes", "Sandals & Slippers", "Jogging Shoes"] },
    { parentCategory: "001", subCategoryName: "Accessories", smallCategoryName: ["Watches", "Sunglasses", "Jewelry & Gems", "Wallets", "Belts"] },
    { parentCategory: "001", subCategoryName: "Grooming & Essentials", smallCategoryName: ["Fragrances", "Skincare", "Hair & Beard Care"] },
    { parentCategory: "002", subCategoryName: "Unstitched Suits", smallCategoryName: ["Cotton", "Cotton Blends", "Linen", "Lawn", "Khaddar", "Karandi", "Wash & Wear", "Boski"] },
    { parentCategory: "002", subCategoryName: "Ready-to-Wear", smallCategoryName: ["Kurtas & Kurtis", "Shalwar Kameez", "Formal Wear", "Dupattas & Shawls"] },
    { parentCategory: "002", subCategoryName: "Footwear", smallCategoryName: ["Khussas & Traditional Sandals", "Heels", "Flats & Slippers", "Sandals & Slippers", "Sneakers & Casual Shoes"] },
    { parentCategory: "002", subCategoryName: "Accessories", smallCategoryName: ["Watches", "Sunglasses", "Jewelry & Gems", "Scarves & Stoles", "Handbags & Clutches"] },
    { parentCategory: "002", subCategoryName: "Grooming & Essentials", smallCategoryName: ["Skincare", "Haircare", "Makeup", "Fragrances", "Henna & Nail Care"] },
    { parentCategory: '003', subCategoryName: 'Clothing', smallCategoryName: ['Shalwar Kameez'] },
    { parentCategory: '003', subCategoryName: 'Footwear', smallCategoryName: ['Sneakers'] },
    { parentCategory: '004', subCategoryName: 'Honey', smallCategoryName: ['Organic Honey'] },
    { parentCategory: '004', subCategoryName: 'Dried Fruits & Nuts', smallCategoryName: ['Almonds'] },
    { parentCategory: '005', subCategoryName: 'Superfoods', smallCategoryName: ['Turmeric Capsules'] },
    { parentCategory: '005', subCategoryName: 'Vitamins', smallCategoryName: ['Multivitamins'] },
    { parentCategory: '006', subCategoryName: 'Accessories', smallCategoryName: ['Earphones'] },
    { parentCategory: '006', subCategoryName: 'Power Banks', smallCategoryName: ['Portable Chargers'] },
  ],
  products: [
    {
      id: '001-01',
      productName: 'Cotton Unstitched Suit',
      description: 'High-quality unstitched cotton suit for men.',
      firstLevelCategory: '001',
      secondLevelCategory: 'Unstitched Suits',
      smallCategoryName: 'Cotton',
      price: 2500,
      oldPrice: 3000,
      isFeatured: true,
      productStock: 50,
      brand: 'Classic Men',
      discount: '10%',
      meters: 2.5,
      colors: ['White', 'Black', 'Blue'],
      ratings: 4.5,
      location: 'Pakistan',
      media: [m1Image, m2Image],
      published: true,
    },
    {
      id: '001-02',
      productName: 'Linen Unstitched Suit',
      description: 'Premium linen fabric for stylish wear.',
      firstLevelCategory: '001',
      secondLevelCategory: 'Unstitched Suits',
      smallCategoryName: 'Linen',
      price: 3000,
      oldPrice: 3500,
      isFeatured: false,
      productStock: 30,
      brand: 'Elite Men',
      discount: '15%',
      meters: 3,
      colors: ['Beige', 'Grey', 'White'],
      ratings: 4.7,
      location: 'Pakistan',
      media: [m1Image, m2Image],
      published: true,
    },
    {
      id: '002-01',
      productName: 'Women Kurti',
      description: 'Elegant kurti for casual wear.',
      firstLevelCategory: '002',
      secondLevelCategory: 'Ready-to-Wear',
      smallCategoryName: 'Kurtas & Kurtis',
      price: 2000,
      oldPrice: 2500,
      isFeatured: true,
      productStock: 50,
      brand: 'Elegant Women',
      discount: '15%',
      sizeOptions: ['Small', 'Medium', 'Large', 'X Large'],
      colors: ['Pink', 'Yellow', 'Green'],
      ratings: 4.6,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Women+Kurti'],
      published: true,
    },
    {
      id: '002-02',
      productName: 'Women Heels',
      description: 'Comfortable and stylish heels.',
      firstLevelCategory: '002',
      secondLevelCategory: 'Footwear',
      smallCategoryName: 'Heels',
      price: 4000,
      oldPrice: 4500,
      isFeatured: false,
      productStock: 30,
      brand: 'Style Hub',
      discount: '10%',
      sizeOptions: ['36', '37', '38', '39', '40'],
      colors: ['Black', 'Red', 'Nude'],
      ratings: 4.5,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Women+Heels'],
      published: true,
    },
    {
      id: '003-01',
      productName: 'Kids Shalwar Kameez',
      description: 'Traditional shalwar kameez for boys.',
      firstLevelCategory: '003',
      secondLevelCategory: 'Clothing',
      smallCategoryName: 'Shalwar Kameez',
      price: 1800,
      oldPrice: 2000,
      isFeatured: false,
      productStock: 40,
      brand: 'Little Stars',
      discount: '10%',
      meters: 1.5,
      colors: ['White', 'Blue', 'Green'],
      ratings: 4.4,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Kids+Shalwar+Kameez'],
      published: true,
    },
    {
      id: '003-02',
      productName: 'Kids Sneakers',
      description: 'Casual sneakers for kids.',
      firstLevelCategory: '003',
      secondLevelCategory: 'Footwear',
      smallCategoryName: 'Sneakers',
      price: 2500,
      oldPrice: 3000,
      isFeatured: true,
      productStock: 50,
      brand: 'Kids Fun',
      discount: '15%',
      meters: null,
      colors: ['Red', 'Blue', 'Yellow'],
      ratings: 4.5,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Kids+Sneakers'],
      published: true,
    },
    {
      id: '004-01',
      productName: 'Organic Honey',
      description: 'Pure and natural organic honey.',
      firstLevelCategory: '004',
      secondLevelCategory: 'Honey',
      smallCategoryName: 'Organic Honey',
      price: 1500,
      oldPrice: 1800,
      isFeatured: true,
      productStock: 100,
      brand: 'HoneyCo',
      discount: '15%',
      meters: null,
      colors: null,
      ratings: 4.8,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Organic+Honey'],
      published: true,
    },
    {
      id: '004-02',
      productName: 'Dried Almonds',
      description: 'Fresh and healthy dried almonds.',
      firstLevelCategory: '004',
      secondLevelCategory: 'Dried Fruits & Nuts',
      smallCategoryName: 'Almonds',
      price: 2000,
      oldPrice: 2500,
      isFeatured: false,
      productStock: 80,
      brand: 'Nutty Bliss',
      discount: '10%',
      meters: null,
      colors: null,
      ratings: 4.7,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Dried+Almonds'],
      published: true,
    },
    {
      id: '005-01',
      productName: 'Turmeric Capsules',
      description: 'Boost your immunity with turmeric capsules.',
      firstLevelCategory: '005',
      secondLevelCategory: 'Superfoods',
      smallCategoryName: 'Turmeric Capsules',
      price: 1000,
      oldPrice: 1200,
      isFeatured: true,
      productStock: 50,
      brand: 'Herbal Care',
      discount: '20%',
      meters: null,
      colors: null,
      ratings: 4.9,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Turmeric+Capsules'],
      published: true,
    },
    {
      id: '006-01',
      productName: 'Wireless Earphones',
      description: 'High-quality wireless earphones with long battery life.',
      firstLevelCategory: '006',
      secondLevelCategory: 'Accessories',
      smallCategoryName: 'Earphones',
      price: 3000,
      oldPrice: 3500,
      isFeatured: true,
      productStock: 30,
      brand: 'SoundPro',
      discount: '15%',
      meters: null,
      colors: ['Black', 'White'],
      ratings: 4.7,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Wireless+Earphones'],
      published: true,
    },
  ],
};
