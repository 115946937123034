import React, { useEffect, useState } from "react";
import PeeralsIcon from "../../assets/images/peeral.png"; // Corrected spelling
import { FaGift, FaShoppingCart, FaPercentage } from "react-icons/fa";
import "../../assets/css/peerals.css"; // Updated CSS file name if necessary
import { useAuth } from "../../context/authContext"; // Import the Auth context to access user info

const Peerals = () => {
  const { user } = useAuth(); // Accessing user info from the AuthContext
  const [peerals, setPeerals] = useState(0); // Peerals state
  const [rupees, setRupees] = useState(0); // Rupees equivalent of peerals

  // Use effect to update peerals and rupees when user data is available
  useEffect(() => {
    if (user) {
      // Assuming `user.pearls` contains the number of peerals
      setPeerals(user.pearls || 0);
      setRupees(user.pearls * 1); // Assuming 1 peerals = 1 INR
    }
  }, [user]);

  return (
    <div className="peerals-page">
      {/* Coins Balance Section */}
      <div className="peerals-balance">
        <div className="balance-box">
          <div className="balance-header">
            <span className="balance-title">My Peerals</span>
            <span className="balance-dropdown">▼</span>
          </div>
          <div className="balance-values">
            <div className="balance-coins">
              <img
                src={PeeralsIcon}
                alt="Peerals Icon"
                className="balance-icon"
              />
              {peerals} {/* Display peerals balance */}
            </div>
            <div className="balance-saves">saves: Rs. {rupees}</div> {/* Display rupees equivalent */}
          </div>
        </div>
      </div>

      {/* Main Header */}
      <div className="peerals-header">
        <h1 className="peerals-title">Peerals</h1>
        <p className="peerals-subtitle">Collect, shop and save</p>
      </div>

      {/* Peerals Rewards System Section */}
      <div className="peerals-rewards">
        <h1 className="rewards-title">Peerals Rewards System</h1>
        <div className="reward-item">
          <FaGift className="reward-icon" />
          <p>
            <strong>Sign-Up Bonus:</strong> Get <strong>200 peerals</strong> instantly when you sign up!
          </p>
        </div>
        <div className="reward-item">
          <FaShoppingCart className="reward-icon" />
          <p>
            <strong>Shopping Bonus:</strong> For every Rs.100 spent, you earn <strong>5 peerals</strong> (5% of your total amount).
            For example, if you spend Rs.1000, you'll earn <strong>50 peerals</strong>!
          </p>
        </div>
        <div className="reward-item">
          <FaPercentage className="reward-icon" />
          <p>
            Use peerals to get discounts on future purchases. Keep shopping to keep earning!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Peerals;
