import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementQty,
  decrementQty,
  removeFromCart,
  clearCart,
} from "../app/actions/actionsCart";
import { useNavigate, Link } from "react-router-dom";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FaStar, FaMinus, FaPlus, FaBolt } from "react-icons/fa";
import { GrClearOption } from "react-icons/gr";
import "../assets/css/cart.css";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.cart);

  // Calculate totals
  const subtotal = cart.reduce(
    (acc, item) => acc + (item.salePrice || item.price) * (item.qty || 1),
    0
  );
  const deliveryCharges = 250;

  // Handlers
  const handleIncrement = (item) => dispatch(incrementQty(item.id));
  const handleDecrement = (item) => {
    if (item.qty > 1) {
      dispatch(decrementQty(item.id));
    }
  };
  const handleRemove = (item) => dispatch(removeFromCart(item.id));

  // Handler for color selection
  const handleColorChange = (item, color) => {
    // Dispatch an action to update the color in the cart state
    dispatch({
      type: "UPDATE_CART_ITEM",
      payload: { id: item.id, selectedColor: color },
    });
  };

  return (
    <div className="cart-main-container">
      <div className="Prduct-banner-container">
        <div className="banner-content">
          <h1>Cart</h1>
          <p>
            <Link to="/">Home</Link> / Cart
          </p>
        </div>
      </div>
      <div className="cart-sub-container">
        {cart.length > 0 ? (
          <>
            <div className="cart-container">
              {cart.map((item) => (
                <div key={item.id} className="cart-item">
                  <div className="cart-left-side-container">
                    <img
                      src={item.media?.[0] || "https://via.placeholder.com/150"}
                      alt={item.productName || "Product Image"}
                      className="cart-item-image"
                    />
                  </div>
                  <div className="cart-right-side-container">
                    <button
                      onClick={() => handleRemove(item)}
                      className="cart-remove-btn"
                      aria-label="Remove item"
                    >
                      <IoIosRemoveCircleOutline size={24} />
                    </button>
                    <h3>{item.productName || "Product Name Not Available"}</h3>
                    <div
                      className="rating"
                      aria-label={`Rating: ${item.ratings || 0}`}
                    >
                      {[...Array(5)].map((_, i) => (
                        <FaStar
                          key={i}
                          className="star"
                          color={
                            i < Math.floor(item.ratings || 0)
                              ? "#ffc107"
                              : "#e4e5e9"
                          }
                        />
                      ))}
                      <span className="rating-value">
                        {(item.ratings ?? 0).toFixed(1)}
                      </span>
                    </div>
                    {/* Quantity Selector */}
                    <div className="quantity-selector">
                      <button
                        onClick={() => handleDecrement(item)}
                        className="quantity-btn"
                        aria-label="Decrease quantity"
                      >
                        <FaMinus />
                      </button>
                      <span className="quantity-value">{item.qty ?? 1}</span>
                      <button
                        onClick={() => handleIncrement(item)}
                        className="quantity-btn"
                        aria-label="Increase quantity"
                      >
                        <FaPlus />
                      </button>
                    </div>
                    {/* Product Color Selector */}
                    {item.colors && (
                      <div className="color-selector">
                        <strong>Color:</strong>
                        <div className="color-options">
                          {item.colors.map((color, index) => (
                            <button
                              key={index}
                              className={`color-btn ${
                                item.selectedColor === color ? "selected" : ""
                              }`}
                              style={{
                                backgroundColor: color.toLowerCase(),
                                border:
                                  item.selectedColor === color
                                    ? "2px solid #000"
                                    : "1px solid #ccc",
                              }}
                              onClick={() => handleColorChange(item, color)}
                              aria-label={`Select ${color} color`}
                            ></button>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* Product Size and Meter */}
                    {item.selectedSize && (
                      <p>
                        <strong>Size:</strong> {item.selectedSize}
                      </p>
                    )}
                    {item.selectedMeter && (
                      <p>
                        <strong>Meterage:</strong> {item.selectedMeter}m
                      </p>
                    )}
                    {/* Price */}
                    <div className="price">
                      {item.originalPrice &&
                      item.originalPrice > item.salePrice ? (
                        <>
                          <span className="old-price">
                            ₨{item.originalPrice.toFixed(2)}
                          </span>
                          <span className="new-price">
                            ₨{item.salePrice.toFixed(2)}
                          </span>
                        </>
                      ) : (
                        <span className="new-price">
                          ₨{item.price.toFixed(2)}
                        </span>
                      )}
                    </div>
                    <p>
                      Total: PKR {(item.salePrice || item.price) * item.qty}
                    </p>
                    <div className="product-summary">
                      <h4>Product Summary</h4>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="cart-summary">
              <p>Subtotal: PKR {subtotal.toFixed(2)}</p>
              <p>Delivery Charges: PKR {deliveryCharges.toFixed(2)}</p>
              <h3>Grand Total: PKR {(subtotal + deliveryCharges).toFixed(2)}</h3>{" "}
              {/* Adding delivery charges to grand total */}
              <button
                onClick={() => dispatch(clearCart())}
                className="clear-cart"
              >
                <GrClearOption /> Clear Cart
              </button>
              <button
                onClick={() => navigate("/checkout", { state: { cart } })}
                className="checkout-button"
              >
                <FaBolt /> Checkout
              </button>
            </div>
          </>
        ) : (
          <div className="cart-empty-container">
            <p>Your cart is empty.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
