import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { LiaCartPlusSolid } from "react-icons/lia";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/home.css";
import { productData } from "../data/productData";
import sliderData from "../data/sliderData";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../app/actions/actionsCart";
import {
  addToFavorites,
  removeFromFavorites,
} from "../app/reducers/favoritesSlice";
import useHorizontalScroll from "../hooks/useHorizontalScroll";


const Home = () => {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [hoveredProduct, setHoveredProduct] = useState({ id: null, index: 0 });
  const [hoverInterval, setHoverInterval] = useState(null);
  const favorites = useSelector((state) => state.favorites);
  const featuredCategories = productData.categories.filter(
    (category) => category.published
  );

  const featuredCategoriesScroll = useHorizontalScroll();
  const categoriesSubNavbarScroll = useHorizontalScroll();

  const isFavorite = (productId) =>
    Array.isArray(favorites) && favorites.includes(productId);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    toast.success(`${product.productName} added to cart!`);
  };

  const handleFavoriteToggle = (product) => {
    if (isFavorite(product.id)) {
      dispatch(removeFromFavorites(product.id));
      toast.info(`${product.productName} removed from favorites.`);
    } else {
      dispatch(addToFavorites(product.id));
      toast.success(`${product.productName} added to favorites!`);
    }
  };

  const handleMouseEnter = (product) => {
    if (product.media.length > 1) {
      let index = 0;
      const interval = setInterval(() => {
        setHoveredProduct((prev) => ({
          id: product.id,
          index: (index + 1) % product.media.length,
        }));
        index++;
      }, 500);
      setHoverInterval(interval);
    }
  };

  const handleMouseLeave = () => {
    clearInterval(hoverInterval);
    setHoveredProduct({ id: null, index: 0 });
  };

  const filteredProducts =
    selectedCategory === "All"
      ? productData.products
      : productData.products.filter(
          (product) => product.firstLevelCategory === selectedCategory
        );

  return (
    <div>
      {/* Slider Section */}
      <div className="carousel-container">
        <Slider
          dots={true}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={3000}
          arrows={true}
        >
          {sliderData.map((slide) => (
            <div key={slide.id} className="carousel-slide">
              <img
                src={slide.image}
                alt={slide.altText}
                className="carousel-image"
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* Featured Categories Section */}
      <section
        className="featured-categories"
        ref={featuredCategoriesScroll.containerRef}
      >
        <h2>Featured Categories</h2>
        <div className="categories-container">
          {featuredCategories.map((category, index) => {
            const backgroundColors = [
              "rgb(254, 239, 234)",
              "rgb(236, 255, 236)",
              "rgb(211, 255, 217)",
              "rgb(232, 244, 255)",
              "rgb(255, 250, 232)",
              "rgb(241, 232, 255)",
            ];
            const bgColor = backgroundColors[index % backgroundColors.length];
            return (
              <Link
                to={`/${category.categoryName
                  .toLowerCase()
                  .replace(/\s+/g, "-")}`}
                key={category.id}
                className="category-card"
              >
                <div
                  className="category-item"
                  style={{ backgroundColor: bgColor }}
                >
                  <img
                    src={category.media[0]}
                    alt={category.categoryName}
                    className="category-image"
                  />
                  <p className="category-name">{category.categoryName}</p>
                </div>
              </Link>
            );
          })}
        </div>
      </section>

      {/* Categories Sub-Navbar */}
      <section
        className="categori-sub-navbar-container"
        ref={categoriesSubNavbarScroll.containerRef}
      >
        <div className="categori-sub-navbar">
          <h2 className="popular-products">Popular Products</h2>
          <nav className="categories-nav-list">
            <button
              className={selectedCategory === "All" ? "active" : ""}
              onClick={() => setSelectedCategory("All")}
            >
              All
            </button>
            {productData.categories.map((category) => (
              <button
                key={category.id}
                className={selectedCategory === category.id ? "active" : ""}
                onClick={() => setSelectedCategory(category.id)}
              >
                {category.categoryName}
              </button>
            ))}
          </nav>
        </div>
      </section>

      {/* Product Container */}
      <section className="product-container">
        <div className="products-grid">
          {filteredProducts.map((product) => (
            <div
              key={product.id}
              className="product-cart"
              onMouseEnter={() => handleMouseEnter(product)}
              onMouseLeave={handleMouseLeave}
            >
              {product.discount && (
                <span className="sale-tag">SALE {product.discount}</span>
              )}
              <img
                src={
                  hoveredProduct.id === product.id
                    ? product.media[hoveredProduct.index]
                    : product.media[0]
                }
                alt={product.productName}
                className="product-image"
              />
              <Link to={`/product-view-details/${product.id}`}className="product-link-views-details">
                <div className="product-info">
                  <h5 className="product-name">{product.productName}</h5>
                  <div className="price">
                    {product.oldPrice && (
                      <span className="old-price">
                        ₨ <del>{product.oldPrice}</del>
                      </span>
                    )}
                    <span className="new-price">₨ {product.price}</span>
                  </div>
                  <div className="rating">
                    {[...Array(5)].map((_, i) => (
                      <FaStar
                        key={i}
                        color={
                          i < Math.floor(product.ratings)
                            ? "#ffc107"
                            : "#e4e5e9"
                        }
                      />
                    ))}
                  </div>
                </div>
              </Link>

              <div className="action-buttons action-list-btn">
                <span
                  className="quick-add-btn quick-add-btn-list"
                  onClick={() => handleAddToCart(product)}
                >
                  <LiaCartPlusSolid />
                </span>
                <span
                  className="wishlist-icon wishlist-icon-list"
                  onClick={() => handleFavoriteToggle(product)}
                >
                  {isFavorite(product.id) ? (
                    <MdFavorite style={{ color: "red" }} />
                  ) : (
                    <MdFavoriteBorder />
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Toast Notifications */}
   <ToastContainer autoClose={500} />
    </div>
  );
};

export default Home;
