const cartificationsData = [
  {
    name: "ISO 9001:2015",
    detail: "Certified for quality management systems to ensure our products meet customer and regulatory standards.",
    image: "/assets/images/certifications/iso9001.png", // Adjust the path to match your folder structure
  },
  {
    name: "FDA Approved",
    detail: "Our products comply with the FDA's stringent requirements for safety and efficacy.",
    image: "/assets/images/certifications/fda.png",
  },
  {
    name: "Organic Certification",
    detail: "Certified organic to guarantee eco-friendly and sustainable production processes.",
    image: "/assets/images/certifications/organic.png",
  },
];

export default cartificationsData;
