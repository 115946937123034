import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/css/chatBot.css';
import mhbLogo from '../assets/images/chatbot_logo.png';

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const openaiApiKey = 'YOUR_OPENAI_API_KEY';

  // Predefined responses for specific questions
  const predefinedResponses = {
    "how to buy?": "To buy a product, browse through our categories, select the item, add it to your cart, and proceed to checkout.",
    "what is your return policy?": "We offer a 30-day return policy. Items must be unused and in original packaging.",
    "do you offer free shipping?": "Yes, we offer free shipping on orders over $50.",
    "what payment methods do you accept?": "We accept credit cards, PayPal, and Apple Pay.",
    "how can I track my order?": "You can track your order using the tracking link sent to your email after shipping.",
    "how to contact customer support?": "You can reach us at support@example.com or call us at +123-456-7890."
  };

  useEffect(() => {
    if (isOpen) {
      const welcomeMessage = {
        sender: 'bot',
        text: 'Welcome to MHB Store! How can I assist you today?'
      };
      setMessages([welcomeMessage]);
    }
  }, [isOpen]);

  const sendMessage = async () => {
    if (!userInput.trim()) return;

    const newMessages = [...messages, { sender: 'user', text: userInput }];
    setMessages(newMessages);
    setUserInput('');

    const lowerCaseInput = userInput.toLowerCase();

    // Check for predefined responses
    if (predefinedResponses[lowerCaseInput]) {
      setMessages([
        ...newMessages,
        { sender: 'bot', text: predefinedResponses[lowerCaseInput] }
      ]);
      return;
    }

    // Fetch response from OpenAI API
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: newMessages.map(msg => ({
            role: msg.sender === 'user' ? 'user' : 'assistant',
            content: msg.text
          }))
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${openaiApiKey}`
          }
        }
      );

      const botMessage = response.data.choices[0].message.content;
      setMessages([...newMessages, { sender: 'bot', text: botMessage }]);
    } catch (error) {
      console.error('Error:', error);
      setMessages([
        ...newMessages,
        { sender: 'bot', text: 'Sorry, something went wrong. Please try again.' }
      ]);
    }
  };

  const handleInputChange = (e) => setUserInput(e.target.value);
  const handleKeyPress = (e) => e.key === 'Enter' && sendMessage();
  const toggleChatBot = () => setIsOpen(!isOpen);

  return (
    <div className="chatbot-wrapper">
      <div className="chatbot-logo-container" onClick={toggleChatBot}>
        <img src={mhbLogo} alt="MHB Store Logo" className="chatbot-logo" />
      </div>
      {isOpen && (
        <div className="chatbot-container">
          <div className="chatbot-header">Chat Support</div>
          <div className="chatbot-messages">
            {messages.map((msg, index) => (
              <div key={index} className={`chatbot-message ${msg.sender}`}>
                {msg.text}
              </div>
            ))}
          </div>
          <div className="chatbot-input-container">
            <input
              type="text"
              value={userInput}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Ask a question..."
              className="chatbot-input"
            />
            <button onClick={sendMessage} className="chatbot-send-button">Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
