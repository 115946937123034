export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREMENT_QTY = 'INCREMENT_QTY';
export const DECREMENT_QTY = 'DECREMENT_QTY';
export const CLEAR_CART = 'CLEAR_CART'; // Action for clearing the cart

export const addToCart = (product) => ({
  type: ADD_TO_CART,
  payload: product,
});

export const removeFromCart = (productId) => ({
  type: REMOVE_FROM_CART,
  payload: productId, // Passing only the product ID for removal
});

export const incrementQty = (productId) => ({
  type: INCREMENT_QTY,
  payload: productId, // Passing only the product ID for increment
});

export const decrementQty = (productId) => ({
  type: DECREMENT_QTY,
  payload: productId, // Passing only the product ID for decrement
});

export const clearCart = () => ({
  type: CLEAR_CART, // Action for clearing the cart
});