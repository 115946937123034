import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { clearCart } from "../app/actions/actionsCart";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Swal from "sweetalert2"; // Import SweetAlert2
import "../assets/css/checkout.css";

// Set your publishable key from Stripe
const stripePromise = loadStripe("your-publishable-key-here");

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const reduxCart = useSelector((state) => state.cart.cart);
  const cartFromState = location.state?.cart || [];
  const cart = cartFromState.length > 0 ? cartFromState : reduxCart;

  const orderId = `ORD-${Math.random()
    .toString(36)
    .substr(2, 9)
    .toUpperCase()}`;

  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
  });
  const [paymentMethod, setPaymentMethod] = useState("creditCard");
  const [selectedBank, setSelectedBank] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const subtotal = cart.reduce(
    (acc, item) => acc + (item.salePrice || item.price) * (item.qty || 1),
    0
  );
  const tax = subtotal * 0.05;
  const shipping = subtotal > 0 ? 15 : 0;
  const grandTotal = subtotal + tax + shipping;

  const handlePlaceOrder = async () => {
    if (
      !userDetails.name ||
      !userDetails.email ||
      !userDetails.address ||
      !userDetails.phone
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill out all user details!",
      });
      return;
    }

    if (paymentMethod === "bank" && !selectedBank) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a bank!",
      });
      return;
    }

    if (paymentMethod === "creditCard" && !stripe) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Stripe has not loaded yet!",
      });
      return;
    }

    if (paymentMethod === "creditCard") {
      const cardElement = elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        Swal.fire({
          icon: "error",
          title: "Payment Failed",
          text: `Payment failed: ${error.message}`,
        });
        return;
      }

      // Call your backend server to process the payment using the token
      try {
        const response = await fetch("/process_payment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token.id,
            amount: grandTotal * 100, // Amount in cents
          }),
        });

        const result = await response.json();
        if (result.success) {
          Swal.fire({
            icon: "success",
            title: "Order Placed!",
            text: `Order ID: ${orderId}`,
          });
          dispatch(clearCart());
        } else {
          Swal.fire({
            icon: "error",
            title: "Payment Failed",
            text: "Payment failed. Please try again.",
          });
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while processing your payment. Please try again.",
        });
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="checkout-container-main">
      {/* Left Section */}
      <div className="product-user-details-container-left">
        {/* Order Summary */}
        <div className="product-detail-container">
          <h3>Your Order</h3>
          <p>
            <strong>Order ID:</strong> {orderId}
          </p>
          {cart.length > 0 ? (
            <ul>
              {cart.map((item) => (
                <li key={item.id} className="checkout-product-item">
                  <img
                    src={item.media?.[0] || "https://via.placeholder.com/150"}
                    alt={item.productName}
                    className="checkout-product-image"
                  />
                  <div className="checkout-product-info">
                    <p>
                      <strong>Product Code:</strong> {item.id}
                    </p>
                    <p>
                      <strong>Name:</strong> {item.productName}
                    </p>
                    {item.selectedColor && (
                      <p>
                        <strong>Color:</strong>
                        <span
                          className="product-color-circle"
                          style={{
                            backgroundColor: item.selectedColor.toLowerCase(),
                          }}
                          title={item.selectedColor}
                        />
                      </p>
                    )}
                    {item.selectedSize && (
                      <p>
                        <strong>Size:</strong> {item.selectedSize}
                      </p>
                    )}
                    {item.selectedMeter && (
                      <p>
                        <strong>Meterage:</strong> {item.selectedMeter}m
                      </p>
                    )}
                    <p>
                      <strong>Qty:</strong> {item.qty}
                    </p>
                    <p>
                      <strong>Price:</strong> ₨{item.salePrice || item.price}
                    </p>
                    <p>
                      <strong>Total:</strong> ₨
                      {(item.salePrice || item.price) * item.qty}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>Your cart is empty.</p>
          )}
        </div>

        {/* User Details */}
        <div className="billing-container">
          <h3 className="billing-header">Billing Details</h3>
          <div className="custmor-details-container">
            <form className="billing-form">
              <div className="billing-form-group">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=" "
                  value={userDetails.name}
                  onChange={handleInputChange}
                  required
                  className="billing-input"
                />
                <label htmlFor="name" className="billing-label">
                  Enter your name
                </label>
              </div>
              <div className="billing-form-group">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder=" "
                  value={userDetails.email}
                  onChange={handleInputChange}
                  required
                  className="billing-input"
                />
                <label htmlFor="email" className="billing-label">
                  Email
                </label>
              </div>
              <div className="billing-form-group">
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder=" "
                  value={userDetails.phone}
                  onChange={handleInputChange}
                  required
                  className="billing-input"
                />
                <label htmlFor="phone" className="billing-label">
                  Phone
                </label>
              </div>
              <div className="billing-form-group">
                <textarea
                  id="address"
                  name="address"
                  rows="5"
                  placeholder=" "
                  value={userDetails.address}
                  onChange={handleInputChange}
                  required
                  className="billing-textarea"
                ></textarea>
                <label htmlFor="address" className="billing-label-textarea">
                  Enter your address
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="payment-details-add-container-right">
        {/* Summary */}
        <div className="bank-details-container">
          <h3>Order Summary</h3>
          <p>
            <strong>Subtotal:</strong> ₨{subtotal.toFixed(2)}
          </p>
          <p>
            <strong>Tax (5%):</strong> ₨{tax.toFixed(2)}
          </p>
          <p>
            <strong>Shipping:</strong> ₨{shipping.toFixed(2)}
          </p>
          <h3>
            <strong>Grand Total:</strong> ₨{grandTotal.toFixed(2)}
          </h3>
        </div>

        {/* Payment Method */}
        <div className="payment-method-container">
          <h3>Payment Method</h3>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              value="creditCard"
              checked={paymentMethod === "creditCard"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            <span>Credit Card</span>
          </label>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              value="cashOnDelivery"
              checked={paymentMethod === "cashOnDelivery"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            <span>Cash on Delivery</span>
          </label>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              value="bank"
              checked={paymentMethod === "bank"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            <span>Bank Transfer</span>
          </label>
          {paymentMethod === "creditCard" && (
            <div className="payment-form-container">
              <CardElement className="card-element" />
            </div>
          )}
          {paymentMethod === "bank" && (
            <div className="bank-selection">
              <select
                id="bank"
                value={selectedBank}
                onChange={(e) => setSelectedBank(e.target.value)}
              >
                <option value="" disabled>
                  Choose a bank
                </option>
                <option value="masterCard">MasterCard</option>
                <option value="bankAlfalah">Bank Alfalah</option>
                <option value="meezanBank">Meezan Bank</option>
                <option value="jazzCash">JazzCash</option>
                <option value="easyPaisa">EasyPaisa</option>
              </select>
            </div>
          )}
        </div>

        <button className="place-order-button" onClick={handlePlaceOrder}>
          Place Order
        </button>
      </div>
    </div>
  );
};

// Wrap your component with Stripe Elements
const StripeCheckoutPage = () => (
  <Elements stripe={stripePromise}>
    <CheckoutPage />
  </Elements>
);

export default StripeCheckoutPage;
