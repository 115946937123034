import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import "../assets/css/adsDiscountPopup.css";
import discountPopupData from "../data/discountPopupData";

const AdsDiscountBannerPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const now = new Date();
    const startDate = new Date(discountPopupData.startDate);
    const endDate = new Date(discountPopupData.endDate);

    // Show popup only if the current time is within the date range and isActive is true
    if (discountPopupData.isActive && now >= startDate && now <= endDate) {
      setShowPopup(true);
      updateTimeLeft(endDate);
    }

    // Timer to update the countdown every second
    const timer = setInterval(() => {
      if (discountPopupData.isActive) {
        updateTimeLeft(endDate);
      }
    }, 1000);

    return () => clearInterval(timer); // Cleanup the timer on component unmount
  }, []);

  const updateTimeLeft = (endDate) => {
    const now = new Date();
    const difference = endDate - now;

    if (difference <= 0) {
      setShowPopup(false); // Hide popup when the countdown ends
    } else {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }
  };

  return (
    showPopup && (
      <div className="ads-discount-popup">
        {/* Background image */}
        <img
          src={discountPopupData.image}
          alt={discountPopupData.title}
          className="ads-discount-popup__background"
        />

        {/* Popup content */}
        <div className="ads-discount-popup__content">
          <h2 className="ads-discount-popup__title">{discountPopupData.title}</h2>
          <p className="ads-discount-popup__description">{discountPopupData.description}</p>
          <p className="ads-discount-popup__time-left">Time left: {timeLeft}</p>

          {/* Close button */}
          <button
            className="ads-discount-popup__close-button"
            onClick={() => setShowPopup(false)}
          >
            <IoMdClose size={24} />
          </button>
        </div>
      </div>
    )
  );
};

export default AdsDiscountBannerPopup;
